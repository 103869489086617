import * as React from 'react';
import styled from 'styled-components';
import Heading from '../../elements/heading/heading';
import { FaChevronRight } from 'react-icons/fa';

export const TeamCardMedia = styled.img`
  border-radius: 4px;
  overflow: hidden;
  width: 100%;
  height: auto;

  @media screen and (min-width: 32.5em) {
    // 520px +
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
`;

export const TeamCardInfo = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media screen and (min-width: 32.5em) {
    padding: 12px;
  }
`;

export const TeamCardHeading = styled(Heading).attrs({
  variant: 'h6',
  as: 'h3',
  hasMargin: false,
})`
  font-weight: 700;

  a {
    text-decoration: none;
    color: #2041a3;

    &:after {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }
  }
`;

export const TeamCardSubHeading = styled.p`
  font-weight: 300;
  margin: 0;
  font-size: 14px;
  line-height: 1.25rem;
  color: rgb(85, 85, 85);
`;

export const TeamCardIcon = styled(FaChevronRight)`
  align-self: center;
  color: rgb(85, 85, 85);

  @media screen and (min-width: 32.5em) {
    display: none;
  }
`;

export interface BaseTeamCardProps {
  mediaSrc?: string;
  heading: string;
  subHeading: string;
  href: string;
  className?: string;
}

export const BaseTeamCard = ({
  mediaSrc,
  heading,
  subHeading,
  href,
  className,
}: BaseTeamCardProps) => {
  return (
    <div className={className}>
      {mediaSrc && <TeamCardMedia src={mediaSrc} alt={heading} />}
      <TeamCardInfo>
        <TeamCardHeading>
          <a href={href}>{heading}</a>
        </TeamCardHeading>
        <TeamCardSubHeading>{subHeading}</TeamCardSubHeading>
      </TeamCardInfo>
      <TeamCardIcon />
    </div>
  );
};

const TeamCard = styled(BaseTeamCard)`
  overflow: hidden;
  width: 100%;
  display: grid;
  grid-template-columns: ${props =>
    props.mediaSrc ? `1fr 1fr auto` : `1fr auto`};
  grid-gap: 12px;
  position: relative;
  background: white;
  transition: background 0.3s ease;

  @media screen and (min-width: 32.5em) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    overflow: hidden;
  }
`;

export default TeamCard;
