//import * as React from 'react';
import styled from 'styled-components';
import Heading from '../../elements/heading/heading';
import TeamCard from '../team-card/team-card-v1';

export const TeamCardSectionList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0 -12px;
  display: flex;
  flex-direction: column;
  background: #f9fafc;
  /* check if bg color on whole element or not */

  li > ${Heading} {
    font-size: 1rem;
    line-height: 1.5rem;
    padding: 12px;
    margin-bottom: 0;
    position: sticky;
    top: -1px;
    z-index: 1;
    background: #f9fafc;
  }

  @media screen and (min-width: 520px) {
    background: none;
    margin: 0 2rem 2rem;

    li > ${Heading} {
      background: none;
      position: static;
      top: auto;
      padding: 0;
      margin-bottom: 12px;
    }
  }
`;

export const TeamCardUnorderedList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0 0 12px;
  display: flex;
  flex-direction: column;

  @media screen and (min-width: 520px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 24px;
    margin: 0 0 24px;
  }

  @media screen and (min-width: 764px) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media screen and (min-width: 1024px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
`;

export const TeamCardListItem = styled.li`
  border-bottom: rgb(85, 85, 85, 0.1) 1px solid;

  ${TeamCard} {
    padding: 12px;
    height: 100%;
  }

  &:hover {
    ${TeamCard} {
      background: rgb(85, 85, 85, 0.025);
    }
  }

  &:focus-within {
    ${TeamCard} {
      background: rgb(85, 85, 85, 0.05);
    }
  }

  @media screen and (min-width: 520px) {
    border: none;
    ${TeamCard} {
      padding: 0px;
    }
  }
`;
