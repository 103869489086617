import React from 'react';
import { graphql } from 'gatsby';
import {
  TeamCardUnorderedList,
  TeamCardListItem,
  TeamCardSectionList,
} from '@csv/styleguide/src/features/team-card-list/team-card-list';
import TeamCard from '@csv/styleguide/src/features/team-card/team-card-v1';
import Container from '@csv/styleguide/src/elements/container/container';
import { PageHeaderComposition } from '@csv/styleguide/src/features/page-header/page-header';
import Layout from '../components/layout';
// import Dames1 from './../images/dames1.jpg';
import Heading from '@csv/styleguide/src/elements/heading/heading';
import { AllTeamsQuery, Sportlink_Team } from '../generated/graphql-types';

const sortAndFilterTeams = (
  teams: [Sportlink_Team],
): { [key: string]: [Sportlink_Team] } => {
  const newTeams = {};

  for (let i = 0; i < teams.length; i++) {
    const currentTeam = teams[i];
    const { ageCategory, gameType } = currentTeam;
    if (gameType === 'G-handbal') {
      newTeams['Rolstoelhandbal']
        ? (newTeams['Rolstoelhandbal'] = [
            ...newTeams['Rolstoelhandbal'],
            currentTeam,
          ])
        : (newTeams['Rolstoelhandbal'] = [currentTeam]);
      // return;
    } else if (ageCategory) {
      newTeams[ageCategory]
        ? (newTeams[ageCategory] = [...newTeams[ageCategory], currentTeam])
        : (newTeams[ageCategory] = [currentTeam]);
    }
  }

  return newTeams;
};

export interface TeamsPageProps {
  data: AllTeamsQuery;
  errors: {
    message: string;
  };
}

const TeamsPage = ({ data, errors }: TeamsPageProps) => {
  const {
    sportlink: { GetTeams: teams },
  } = data;

  if (!teams || errors) {
    return <p>Errors occurred</p>;
  }
  const sortedTeams = sortAndFilterTeams(teams);

  const shouldRenderList = teams && teams.length;

  return (
    <Layout>
      <article>
        <PageHeaderComposition
          title="Teams"
          byline="Alle teams van C.S.V. Handbal in de verschillende competitietypes:
          Veld, Zaal en Beach."
        />
        <Container>
          {shouldRenderList ? (
            <TeamCardSectionList>
              {Object.keys(sortedTeams).map((ageCategory: string) => (
                <li key={ageCategory}>
                  <Heading as={'h2'} variant={'h2'}>
                    {ageCategory}
                  </Heading>
                  <TeamCardUnorderedList>
                    {sortedTeams[ageCategory].map((team: Sportlink_Team) => (
                      <TeamCardListItem key={team.teamId}>
                        <TeamCard
                          heading={team.teamName || ''}
                          subHeading={`${team.gameType} - ${team.gender}`}
                          href={`/team/${team.teamId}`}
                        />
                      </TeamCardListItem>
                    ))}
                  </TeamCardUnorderedList>
                </li>
              ))}
            </TeamCardSectionList>
          ) : (
            'geen teams gevonden'
          )}
        </Container>
      </article>
    </Layout>
  );
};

export const query = graphql`
  query AllTeams {
    sportlink {
      GetTeams {
        ageCategory
        competition
        competitionType
        divisionGroupName
        divisionName
        gameType
        gender
        groupName
        groups {
          groupId
        }
        teamGameDay
        teamId
        teamName
        teamType
      }
    }
  }
`;

export default TeamsPage;
